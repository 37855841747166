// NEWS
.news-img-wrapper {
  height: 22em;
  width: 22em;
  max-width: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

@media screen and (min-width: 32em) {
  .news-img-wrapper {
    width: 50%;
    float: right;
  }

  .news-content {
    width: 50%;
    float: right;
    padding-left: 48px;
    padding-top: 48px;
  }
}
