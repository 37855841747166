@import "variables.less";
@import "reset.less";
@import "links.less";
@import "typography.less";
@import "layout.less";
@import "animations.less";

// SECTIONS
@import "sections/header.less";
@import "sections/overview.less";
@import "sections/poweredby.less";
@import "sections/mycryptos.less";
@import "sections/news.less";
@import "sections/market.less";
@import "sections/footer.less";
@import "sections/useIt.less";

/*UTILITIES*/
.panel {
  position: relative;
  border: 1px solid #e3e7e8;
  background-color: #fff;
  border-radius: 4px;
}

.list-space-1 > li {
  margin-bottom: 8px;
}

.list-space-2 > li {
  margin-bottom: 16px;
}

.list-no-padding {
  padding-left: 1em;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.with-shadow {
  box-shadow: 0 0 8px 0px rgba(0,0,0,0.2);
}

.with-gradient {
  background: linear-gradient(135deg, #0084b9 0%,#1ca692 100%);
}

/*CONTENT*/

.section-title {
  color: @color-blue-aim;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.section-title-gray {
  color: #666;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.bg-white {
  background-color: #fff;
}

.image {
  max-width: 100%;
  height: auto;
}
