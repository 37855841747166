@import (inline) "~normalize.css/normalize.css";

html {
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  display: flex;
  min-height: 100%;
  margin: 0;
  padding-top: 64px;
  font: 16px "Lucida Grande", Helvetica, Arial, sans-serif;
  background-color: #f5f5f5;
  overflow-x: hidden;
}
