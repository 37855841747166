.txt-gray {
  color: #636d79;
}

.txt-center {
  text-align: center;
}

.txt-white {
  color: #fff;
}

.txt-12 {
  font-size: 1.2em;
}

// HEADINGS
.h3 {
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.1em;
  font-size: 1em;
}
