
.slideshow {
  animation-name: slideshow;
  animation-duration: 20s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes slideshow {
  0% {transform: translateX(0px);}
  50% {transform: translateX(-290px);}
  100% {transform: translateX(0px);}
}

.slideshow2 {
  animation-name: slideshow2;
  animation-duration: 20s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes slideshow2 {
  0% {transform: translateX(50px);}
  50% {transform: translateX(-280px);}
  100% {transform: translateX(50px);}
}

.slideshow3 {
  animation-name: slideshow3;
  animation-duration: 15s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes slideshow3 {
  0% {transform: translateX(50px);}
  50% {transform: translateX(-330px);}
  100% {transform: translateX(50px);}
}
