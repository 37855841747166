.main-footer {
  bottom: 0;
  color: #666;
  font-size: 0.8em;
  height: 32px;
  left: 0;
  padding: 8px 32px;
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: #f0f0f0;
  box-shadow: 0 0px 2px rgba(0,0,0,0.1);
}

.main-footer-content {
  display: flex;
  justify-content: center;
  opacity: 1;
}
