// POSITION
.pos-rel {
  position: relative;
}

.align-bottom {
  vertical-align: bottom;
}

// MARGINS
.no-margin {
  margin: 0;
}

// PADDING
.padded-2 {
  padding: 32px;
}

// DIMENSIONS
.height-100vh {
  height: 100vh;
}

// SPACERS
.space-0 {
  margin: 0 !important;
}

.space-top-0 {
  margin-top: 0 !important;
}

.space-top-2 {
  margin-top: 2 * 16px;
}

.space-right-1 {
  margin-right: 16px;
}

.space-right-1ch {
  margin-right: 1ch;
}

.space-left-1ch {
  margin-left: 1ch;
}

.space-right-2 {
  margin-right: 32px;
}

.space-bottom-0 {
  margin-bottom: 0 !important;
}

.space-bottom-1 {
  margin-bottom: 16px;
}

.space-bottom-2 {
  margin-bottom: 32px;
}

.space-bottom-3 {
  margin-bottom: 48px;
}

.space-bottom-4 {
  margin-bottom: 64px;
}

.space-top-2 {
  margin-top: 32px;
}

// CONTAINERS GRID
.container {
  min-width: 16em;
  max-width: 48em;
  margin-left: auto;
  margin-right: auto;
}

.row {
  margin-left: -16px;
  margin-right: -16px;
}

.row::after,
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.row {
  margin-left: -16px;
  margin-right: 16px;
}

.col-m-2,
.col-m-3 {
  padding-left: 8px;
  padding-left: 8px;
}

@media screen and (min-width: 32em) {
  .col-m-3 {
    width: 33.333%;
    float: left;
  }

  .col-m-2 {
    width: 50%;
    float: left;
  }
}

// Height
.height-100p {
  min-height: 100%;
}

// FLEX
.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-fill {
  flex-grow: 1;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-center {
  display: flex;
  align-items: center;
}

.flex-row-end {
  margin-left: auto;
}

.flex-row-spread {
  display: flex;
  justify-content: space-between;
}

// FLOAT
.float-right {
  float: right;
}

// GRID
@grid-fluid-values: 16em, 28em;

each(@grid-fluid-values, .(@gvalue) {
  .grid-fluid-colmin@{gvalue} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(@gvalue, 1fr));
    grid-gap: @spacing-base;
  }
});
