// MY CRYPTOS
.img-wrapper-portfolio {
  height: 194px;
  overflow: hidden;
  position: relative;
  border-radius: 6px;
  margin-bottom: 16px;
}

.big-arrow {
  transform: rotateZ(90deg);
  text-align: center;
  color: #ececec;
  font-size: 3em;
}

.col-portfolio {
  margin-bottom: 32px;
}

.my-cryptos-img-wrapper {
  height: 22em;
  width: 22em;
  max-width: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.my-cryptos-img-wrapper.screenshot {
  position: absolute;
  top: -25px;
  left: -28px;
}

.img-wrapper-portfolio-img {
  width: auto;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (min-width: 32em) {
  .my-cryptos-content {
    width: 50%;
    float: left;
    padding-right: 48px;
    padding-top: 32px;
  }

  .my-cryptos-img-wrapper {
    width: 50%;
    float: left;
  }

  .img-wrapper-portfolio-img {
    /* position: absolute;
    top: 0;
    left: 0; */
  }

  .col-portfolio {
    width: 50%;
    float: left;
    padding-left: 16px;
    padding-right: 16px;
  }
}
