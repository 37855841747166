.section-useit {
  background-color: #f0f0f0;
}

.useit__description {
  margin-top: 32px;

  @media (min-width: 32em) {
    margin-top: 110px;
  }
}
